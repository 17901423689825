<div class="menu">
        
    <mat-card class="cardPronto  mat-elevation-z12" >
        
        Simulado da Certificação<br /><br />

        <img class="in"  src="../../../assets/image/practitioner.png" alt="Practitioner" >
        <br />
        <div >
        <mat-form-field appearance="outline" >
            <mat-label >Sequecione a Quantidade de Questões</mat-label>
            <mat-select >
              <mat-option *ngFor="let item of quantidade" 
                         [value]="item.value"
                         class="select"
                         (onSelectionChange)="acessarSimulado(item.value)">
                {{item.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
    </mat-card>

    <mat-card class="cardConstrucao  mat-elevation-z12">
        
        Simulado em Construção<br /><br />
        <img class="in"  src="../../../assets/image/solution.png" alt="Practitioner">
    </mat-card>

    <mat-card class="cardConstrucao  mat-elevation-z12">
        
        Simulado em Construção<br /><br />
        <img class="in"  src="../../../assets/image/developerassociate.png" alt="Practitioner">
    </mat-card>

</div>