
<div class="novo">

    <h1>Propósito</h1>

    Cabeça na nuvem é um projeto social que tem como objetivo proporcionar 
    diversidade e inclusão na área de Tecnologia.<br />
        
    Uma das vertentes do projeto é a produção de um canal com conteúdo gratuito 
    e em português sobre tecnologia na nuvem, com foco em 
    formação e habilitação para certificação! Contanto hoje com 
    mais de 12 horas de conteúdo sobre cloud AWS e simulados para certificação.<br />
    Outra vertente tem sido buscar recursos para doações de notebooks para quem quer 
    ingressar na área de tecnologia, algo que tem sido uma grande barreira para quem está buscando ingressar na área!<br />
    Até o momento foram feitas duas ações de doação de notebooks <br />
    
    <br />
    <p>
    Através de mentoria com diversas pessoas que estão buscando ingressar na área de tecnologia, seja no seu primeiro emprego ou até para aqueles que buscam uma mudança de carreira, 
    pude perceber que o acesso à uma infraestrutura mínima para o estudo (desktop ou notebook) tem sido uma grande barreira, pois muitos possuem apenas um celular e em alguns casos um tablet. Impossibilitando o uso de ferramentas de desenvolvimento e engenharia de software.
    Sendo assim, iniciei um processo de doações, seja via recurso próprio, doações de notes/desktops e contribuições financeiras.
    Caso queira contribuir com o projeto, me enviem um e-mail: <b>cristianopelizzari@gmail.com</b></p>
    
    <p>Para mais informações pessoais acessem o a cessão do <b>Criador</b></p>
    A primeira ação feita presenteou a Luna, mulher trans, moradora de Diadema - SP que vinha estudando sobre tecnologia apenas pelo seu celular. Com a doação
    passou a ter 
    o recurso necessário para se desenvolver na área, veja abaixo essa e outras campanhas feitas através do projeto:
    <br />
    <br />

    <div class="postsOrg">
        <div class="alinhamento">
            <mat-card class="cardPronto  mat-elevation-z12" >
                <mat-card-header>
                    <div mat-card-avatar class="luna-header-image"></div>
                    <mat-card-title>Luna Valentina</mat-card-title>
                    <mat-card-subtitle>Futura Dev</mat-card-subtitle>
                  </mat-card-header>
                  <img mat-card-image  src="../../../assets/image/luna.JPG" alt="Luna Valentina">
                  
                  <mat-card-content  >
                    
                    <div class="contCard"> 
                        
                        <div>
                            A ganhadora da primeira campanha se chama Luna, ela é uma garota trans, moradora de Diadema - SP e vem buscando entrar na área de 
                            tecnologia há algum tempo!  <br />
                            Recentemente foi aprovada no processo do REPROGRAMA, porém pela falta de um note/pc acabou não podendo participar da turma de fev.<br />
        
                            Após ter acesso à um notebook e se inscrever pela segunda vez no programa, passou a integrar o grupo de formação de mulheres do REPROGRAMA<br />
        
                            Uma pessoa incrível, que tive o prazer de conhecer e poder contribuir com sua jornada!<br />
                            Tenho certeza que ela vai ter não só muito sucesso, mas vai inspirar muitas outras à entrar na área de tecnologia e buscar também seus sonhos
                            <br /><br />
                            <b>links: </b><br />
                            Instagram Luna: 
                            <a href="https://www.instagram.com/lunasemfronteiras/" target="_blank"> lunasemfronteiras</a> <br />
                            <a href="https://www.linkedin.com/in/transdev/" target="_blank"> Linkedin Luna </a><br />
                            <a href="https://www.linkedin.com/posts/cristianopelizzari_ol%C3%A1-pessoal-vim-divulgar-a-pessoa-que-foi-activity-6785517667651477504-v_L_" target="_blank"> Post anúncio Linkedin </a> <br />
                            <a href="https://www.instagram.com/p/CNU_oaOH5xf/?utm_source=ig_web_copy_link" target="_blank">Post anúncio Instagram</a>
                        </div>
                        
                    </div>
                    
                  </mat-card-content>
             
            </mat-card> 
        </div>
        <div><br /><br /></div>
        <div class="alinhamento">
            <mat-card class="cardPronto  mat-elevation-z12" >
                <mat-card-header>
                    <div mat-card-avatar class="luyza-header-image"></div>
                    <mat-card-title>Luyza</mat-card-title>
                    <mat-card-subtitle>Estudante - Desenvolvimento de Sistemas</mat-card-subtitle>
                  </mat-card-header>
                  <img mat-card-image  src="../../../assets/image/luyza.JPG" alt="Luyza">
                  
                  <mat-card-content  >
                    
                    <div class="contCard"> 
                        
                        <div>
        
                            Nessa segunda edição da campanha de doação 
                            a pessoa que tive o imenso prazer de conhecer e ajudar um pouco em sua trajetória foi a Luyza Brito De Paiva Lima 19 anos, 
                            reside atualmente no Heliópolis, maior favela da cidade de São Paulo, com mais de 200 mil habitantes nos dias de hoje.<br />
                            Ela ficou desempregada durante a pandemia, e por não estar em um trabalho formal,
                            não teve direito à auxílios do governo e seguro desemprego. <br />    
                            Ela acredita que os estudos e a tecnologia podem mudar sua realidade! Atualmente estuda Análise e desenvolvimento
                            de sistemas na ETEC - Escola Técnica Estadual de São Paulo - na unidade do Heliópolis. <br />
                            O Fato de não ter um equipamento tem dificultado muito o acompanhamento das aulas, atualmente no modelo remoto, e não poder contar com a infraestrutura da 
                            escola técnica prejudica muito sua experiência e aprendizado.
                            <br /><br />
                            <b>links: </b><br />
                            Instagram Luyza: <a href="https://www.instagram.com/luh_britoh/" target="_blank">luh_britoh </a> <br />
                            <a href="https://www.linkedin.com/in/luyza-brito-de-paiva-lima-2474521a4/" target="_blank"> Linkedin Luyza </a><br />
                            <a href="https://www.linkedin.com/posts/cristianopelizzari_ol%C3%A1-pessoal-aproveitando-o-hor%C3%A1rio-do-almo%C3%A7o-activity-6807705515884326912-4sbq" target="_blank"> Post anúncio Linkedin </a> <br />
                            <a href="https://www.instagram.com/p/CPwXvoKMPhO/?utm_source=ig_web_copy_link" target="_blank">Post anúncio Instagram</a>
                        </div>
                        
                    </div>
                    
                  </mat-card-content>
             
            </mat-card> 
        </div>
        <div><br /><br /></div>
        <div class="alinhamento">
            <mat-card class="cardPronto  mat-elevation-z12" >
                <mat-card-header>
                    <div mat-card-avatar class="elton-header-image"></div>
                    <mat-card-title>Elton</mat-card-title>
                    <mat-card-subtitle>Estudante ADS ETEC</mat-card-subtitle>
                  </mat-card-header>
                  <img mat-card-image  src="../../../assets/image/elton.JPG" alt="Cristiano Pelizzari">
                  
                  <mat-card-content>
                    <div class="contCard"> 
                        <div>              
                            Esse é o Elton, 41 anos, casado e com dois filhos. Trabalha como repositor de bikes na TemBici . 
                            Sempre sonhou com a possibilidade de trabalhar com tecnologia e proporcionar mais para sua família. 
                            Se inscreveu em todas as campanhas e acreditou que sua vez também poderia chegar. 
                            Conversamos muito e fiquei muito feliz de entre algumas recomendações ele se inscreveu e passou a ETEC - 
                            Centro Paula Souza para o curso de tecnologia. Além de já estar estudando Python também.<br>
                            Com essa ação espero poder ajudá-lo a quebrar barreiras e mostrar que não há idade para mudar e 
                            buscar uma nova profissão ou carreira!.

                            <br /> <br />
                            <b>links: </b><br />
                            Instagram Elton: <a href="https://www.instagram.com/eltinho_sp/" target="_blank">eltinho_sp</a> <br />
                            <a href="https://www.linkedin.com/in/elton-santos-658655204/" target="_blank"> Linkedin Elton </a><br />
                            <a href="https://www.linkedin.com/posts/cristianopelizzari_ol%C3%A1-pessoal-passando-para-divulgar-o-ganhador-activity-6817846909697302528-eX4f" target="_blank"> Post anúncio Linkedin </a> <br />
                            <a href="https://www.instagram.com/p/CQ3ihb4Mn5l/?utm_source=ig_web_copy_link" target="_blank">Post anúncio Instagram</a>
                        </div>
                    </div>
                  </mat-card-content>
            </mat-card> 
        </div>
    </div>

    <br /><br />

    <div class="postsOrg">
        <div class="alinhamento">
            <mat-card class="cardPronto  mat-elevation-z12" >
                <mat-card-header>
                    <div mat-card-avatar class="raquel-header-image"></div>
                    <mat-card-title>Raquel Alves</mat-card-title>
                    <mat-card-subtitle>FATEC SCS - ADS</mat-card-subtitle>
                  </mat-card-header>
                  <img mat-card-image  src="../../../assets/image/raquel.jpg" alt="Raquel Alves">
                  
                  <mat-card-content  >
                    
                    <div class="contCard"> 
                        
                        <div>
                            Raquel é uma pessoa incrível, tem 18 anos e foi aceita na FATEC, faculdade estadual de tecnologia de São Paulo. Porém, assim como
                            muitos Brasileiros, não possui um equipamento adequado para fazer suas atividades e com isso tem tido diversas dificuldades em acompanhar o nível do curso.<br />
                            Um dos agravantes da pandemia do COVID-19 foi o fato dos alunos não poderem contar com a estrutura das universidades, dificultando ainda mais a chance de 
                            aprenderem e poderem assim mudarem sua realidade. <br />
                            Tenho certeza que será uma grande profissional e não só consquistar tudo que sonha, mas também inspirar muitas outras mulheres em também buscar o seu espaço na 
                            área de técnologia, inspirando muitas outras à seguirem seus passos!
                            
                            <br /><br />
                            <b>links: </b><br />
                            Instagram Raquel: 
                            <a href="https://www.instagram.com/sonryax/" target="_blank"> sonryax</a> <br />
                            
                            <a href="https://www.linkedin.com/posts/cristianopelizzari_mulheresnati-mulheresnatecnologia-mulheresprofissionais-activity-6828745434878488577-wpJA" target="_blank"> Post anúncio Linkedin </a> <br />
                            <a href="https://www.instagram.com/p/CR9n6S0FMOu/?utm_source=ig_web_copy_link" target="_blank">Post anúncio Instagram</a>
                        </div>
                        
                    </div>
                    
                  </mat-card-content>
             
            </mat-card> 
        </div>
        <div><br /><br /></div>
        <div class="alinhamento">
            <mat-card class="cardPronto  mat-elevation-z12" >
                <mat-card-header>
                    <div mat-card-avatar class="gab-header-image"></div>
                    <mat-card-title>Gabriel Oliveira</mat-card-title>
                    <mat-card-subtitle>FATEC SCS - ADS</mat-card-subtitle>
                  </mat-card-header>
                  <img mat-card-image  src="../../../assets/image/gabriel.jpg" alt="Gabriel">
                  
                  <mat-card-content  >
                    
                    <div class="contCard"> 
                        
                        <div>
        
                            Gabriel é morador de Diadema - SP, estudante de técnologia na FATEC SP e formado pela ETEC Diadema. Apaixonado por tecnologia e segurança da informação
                            vem trilhando sua história com muita dificuldade por não ter um equipamento próprio, tendo que depender de emprestimos e utilização do PC de parentes, que
                            além ser um meio muito restrito e embaraçoso, tais equipamentos não possuem os requisitos mínimos para performar as atividades exigidos na universidade.
                            Sua paixão fez com que ele pudesse superar todas as dificuldades encontradas até o momento. Não tenho dúvidas que terá muito sucesso em sua jornada,
                            pois já superou diversas dificuldades pra se manter nessa trilha sem ter o mínimo necessário!
                            <br /><br /><br /><br />
                            <b>links: </b><br />
                            Instagram Gabriel: <a href="https://www.instagram.com/biel.py/" target="_blank">biel.py </a> <br />
                            
                            <a href="https://www.linkedin.com/posts/cristianopelizzari_sextou-activity-6865055777200074753-BFsu" target="_blank"> Post anúncio Linkedin </a> <br />
                            <a href="https://www.instagram.com/p/CV3WsevPrKI/?utm_source=ig_web_copy_link" target="_blank">Post anúncio Instagram</a>
                        </div>
                        
                    </div>
                    
                  </mat-card-content>
             
            </mat-card> 
        </div>
        <div><br /><br /></div>
        <div class="alinhamento">
            <mat-card class="cardPronto  mat-elevation-z12" >
                <mat-card-header>
                    <div mat-card-avatar class="willy-header-image"></div>
                    <mat-card-title>Williane Felix</mat-card-title>
                    <mat-card-subtitle>Estudante - IFPE</mat-card-subtitle>
                  </mat-card-header>
                  <img mat-card-image  src="../../../assets/image/willy.jpg" alt="Williane Felix">
                  
                  <mat-card-content>
                    <div class="contCard"> 
                        <div>              
                            Williane é natural de Pernambuco, tem 26 anos, é casada e tem duas filhas lindas. Nos falamos desde a primeira campanha quando ainda estudava na escola técnica.
                            Sua determinação me chamou atenção! Mesmo com todas as dificuldades, pandemia, desemprego, falta de equipamento, não desistiu do seu sonho! <br />
                            Ela vem todo esse tempo estudando apenas pelo celular, sem desanimar e se mantendo focada, acreditando assim como eu que apenas o conhecimento 
                            pode mudar nossa realidade! Atualmente estuda na IFPE (Instituto Federal de Pernambuco). <br />
                            Tenho certeza que nada irá atrapalhar sua trajetória e que todas as dificuldades que ainda virão serão superadas com alegria e amor de sua família. <br />
                            Dejeso sucesso e que sua história inspire muitas outras à buscarem seus sonhos com a alegria e coragem que você possuí! <br />

                            <br /> 
                            <b>links: </b><br />
                            Instagram Willy: <a href="https://www.instagram.com/willyfelixx/" target="_blank">willyfelixx</a> <br />
                            <a href="https://www.linkedin.com/in/willianefelix/" target="_blank"> Linkedin Williane </a><br />
                            <a href="https://www.linkedin.com/posts/cristianopelizzari_sextou-activity-6865055777200074753-BFsu" target="_blank"> Post anúncio Linkedin </a> <br />
                            <a href="https://www.instagram.com/p/CWBetO5PX85EAfCqPMlNQcu6QaclAfy8U76LfU0/" target="_blank">Post anúncio Instagram</a>
                        </div>
                    </div>
                  </mat-card-content>
            </mat-card> 
        </div>
    </div>

    
    <br /><br />

    <div class="postsOrg">
        <div class="alinhamento">
            <mat-card class="cardPronto  mat-elevation-z12" >
                <mat-card-header>
                    <div mat-card-avatar class="nath-header-image"></div>
                    <mat-card-title>Nathalia Elen</mat-card-title>
                    <mat-card-subtitle>IFPE</mat-card-subtitle>
                  </mat-card-header>
                  <img mat-card-image  src="../../../assets/image/nath.JPG" alt="Nathalia Elen">
                  
                  <mat-card-content  >
                    
                    <div class="contCard"> 
                        
                        <div>
                            Nathalia é natural de Pernambuco, nascida e criada na pequena cidade de Abreu de Lima. Apesar de sua família possuir poucos recursos financeiros
                            ela vem sempre se dedicando muito aos estudos e acredita que educação pode mudar sua realidade! Conseguiu através de seu esforço ingressar em diferentes escolas técnicas e inclusive
                            na IFPE (Instituto Federal de Pernambuco). Mas como acompanhar o rítmo e toda experiência do curso sem ter um equipamento adequado? Vinha estudando e tendo como recurso
                            apenas seu celular, que não atende o mínimo necessário, ainda mais em um curso de tecnologia! <BR />
                            Fico muito feliz de poder conhecer sua história de vida, de muita luta e dedicação! acredito que vai inspirar muitas outras de sua
                            região em também buscar seus sonhos e objetivos!
                            
                            <br /><br /><br /> 
                            <b>links: </b><br />
                            Instagram: 
                            <a href="https://www.instagram.com/ops.nathalia/" target="_blank"> ops.nathalia</a> <br />
                            
                            <a href="https://www.linkedin.com/posts/nath%C3%A1lia-elen-5043ab1b2_eu-quero-agradecer-aqui-ao-cristiano-pelizzari-activity-6884906253512970241-opx_" target="_blank"> Post Anúncio Linkedin </a> <br />
                            <a href="https://www.instagram.com/p/CYZv7qUv0wV/?utm_source=ig_web_copy_link" target="_blank">Post anúncio Instagram</a>
                        </div>
                        
                    </div>
                    
                  </mat-card-content>
             
            </mat-card> 
        </div>
        <div><br /><br /></div>
        <div class="alinhamento">
            <mat-card class="cardPronto  mat-elevation-z12" >
                <mat-card-header>
                    <div mat-card-avatar class="wendell-header-image"></div>
                    <mat-card-title>Wendel Rojas</mat-card-title>
                    <mat-card-subtitle>Venezuela</mat-card-subtitle>
                  </mat-card-header>
                  <img mat-card-image  src="../../../assets/image/wendell.JPG" alt="Wendell">
                  
                  <mat-card-content  >
                    
                    <div class="contCard"> 
                        
                        <div>
        
                            Wendell é venezuelano, veio ao brasil há apoximadamente 3 anos. Na Venezuela havia se formado em psicologia e atuava em clinicas com paciêntes com depressão
                            e que também não tinham renda para o tratamento, ajudando como voluntário!
                            Veio para o Brasil na expectativa de se estabelecer e conseguir através da sua profissão, sustentar sua família, porém a realidade não é tão simples.
                            Vem passando por um grande processo de validação de documentos tanto para sua estadia aqui quanto encontrar um emprego formal. O que fez com que passasse à 
                            trabalhar apenas em empregos informais, morar na periferia e enfrentar ainda assim muita dificuldade em se estabelecer aqui no Brasil.
                            Vem estudando tecnologia e acredita também que a educação, em especial na tecnologia, pode abrir muitas portas! área quem vem crescendo exponenciamente no mundo,
                            e abertos muitas portas para estudantes, pessoas de baixa renda e em situações de fragilidade!
                            Espero que esse pequeno gesto impulsione seus estudos, carreira e uma nova vida aqui no brasil!
                            <br /><br />
                            <b>links: </b><br />
                            Instagram Wendell: <a href="https://www.instagram.com/wenrart/" target="_blank">wenrart </a> <br />
                            
                            <a href="https://www.linkedin.com/posts/wendell-a-r-camejo-a37b1b179_muitas-vezes-sonhamos-com-ter-a-oportunidade-activity-6884195576922071040-OU9e" target="_blank"> Post anúncio Linkedin </a> <br />
                            <a href="https://www.instagram.com/p/CYUTigNl_aN/?utm_source=ig_web_copy_link" target="_blank">Post anúncio Instagram</a>
                        </div>
                        
                    </div>
                    
                  </mat-card-content>
             
            </mat-card> 
        </div>
        <div><br /><br /></div>
        <div class="alinhamento">
            <mat-card class="cardPronto  mat-elevation-z12" >
                <mat-card-header>
                    <div mat-card-avatar class="lima-header-image"></div>
                    <mat-card-title>Lais Lima</mat-card-title>
                    <mat-card-subtitle>Estudante - USP</mat-card-subtitle>
                  </mat-card-header>
                  <img mat-card-image  src="../../../assets/image/lima.jpg" alt="Lais Lima">
                  
                  <mat-card-content>
                    <div class="contCard"> 
                        <div>   <br />            
                            Lais é natural de São Paulo e mora na região de Campo Limpo - SP, é estudante da USP e já participou de uma formação de desenvolvedores
                            do programa Generation! Não dispõem de um equipamento para estudos e quando o programa Generation se encerrou, alguns meses depois teve que desenvolver
                            a máquina que recebeu como empréstimo para realizar suas atividades no programa. <br /> 
                            Seguia seus estudos com muita dificuldades por não possuir um equipamento e também com muito esforço 
                            mesmo para manter o conhecimento adquirido no bootcamp Generation, uma vez que teve que interromper os estudos após a devolução do notebook emprestado!
                             Os ultimos anos devido à pandemia tem sido muito difíceis,
                            estudos de forma remota, falta de equipamento, desemprego e além de todas as dificuldades, sua família não apoia em sua jornada de estudos!
                            Fico muito feliz de apoiar com esse pequeno gesto, e quem sabe impulsionar a carreira e os estudos de uma pessoa tão incrível e dedicada!

                            <br /> <br /> <br /> 
                            <b>links: </b><br />
                            Instagram Lais: <a href="https://www.instagram.com/limalaiswell/" target="_blank">limalaiswell</a> <br />
                            <a href="https://www.linkedin.com/in/lais-lima-dev/" target="_blank"> Linkedin  </a><br />
                            <a href="https://www.linkedin.com/posts/lais-lima-dev_aws-nuvem-devs-activity-6879882538505035776-dtSP" target="_blank"> Post anúncio Linkedin </a> <br />
                            <a href="https://www.instagram.com/p/CX1xuXTvD3a/?utm_source=ig_web_copy_link" target="_blank">Post anúncio Instagram</a>
                        </div>
                    </div>
                  </mat-card-content>
            </mat-card> 
        </div>
    </div>

    <br /><br />

    <div class="postsOrg">
        <div class="alinhamento">
            <mat-card class="cardPronto  mat-elevation-z12" >
                <mat-card-header>
                    <div mat-card-avatar class="nathalia-header-image"></div>
                    <mat-card-title>Nathalia Ribeiro Silvestre</mat-card-title>
                    <mat-card-subtitle>Engenheira de software</mat-card-subtitle>
                  </mat-card-header>
                  <img mat-card-image  src="../../../assets/image/natahliaribeiro.jpeg" alt="Nathalia Elen">
                  
                  <mat-card-content  >
                    
                    <div class="contCard"> 
                        
                        <div>
                            Nathalia nasceu e cresceu na periferia de São Paulo, e como todos os brasileiros, 
                            sofreu muito durante a pandemia!<br />
                            Sua única fonte de renda era através de entregas feitas de bicicleta administrada por aplicativos de delivery! <br />
                            Além de toda dificuldade durante uma de suas entregas sofreu um acidente grave com fratura exposta, fato que à 
                            deixou ainda mais fragilizada durante a maior pandemia mundial já registrada.<br />
                            Em 2021 conseguiu um notebook emprestado no programa Generation após passar no processo 
                            seletivo para estudar no bootcamp! Assim que teve esse primeiro contato com a tecnologia passou à 
                            acreditar que através do conhecimento e tecnologia poderia mudar sua realidade!<br />
                            Ao final do bootcamp ela teve que devolver o notebook e ficou assim sem sua principal ferramenta de estudos. <br />
                            Mesmo com tantas dificuldades ela tem um projeto social chamado Nós por Nós, que distribui cestas básicas em comunidades de São Paulo.<br />
                            Espero que esse gesto possa contribuir com sua história, tenho certeza de que irá conquistar muito em sua vida, com esse coração gigante! Além das conquistas tenho certeza que irá inspirar muitas pessoas como ela, que sonham em mudar de vida através do conhecimento.

                            
                            <br /><br /><br /> 
                            <b>links: </b><br />
                            Instagram: 
                            <a href="https://www.instagram.com/nathalia.silvestree/" target="_blank"> nathalia.silvestree</a> <br />
                            
                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:6894779217326743552?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A6894779217326743552%29" target="_blank"> Post Anúncio Linkedin </a> <br />
                            <a href="https://www.linkedin.com/in/nathalia-ribeiro-silvestre-381849215/" target="_blank">Instagram</a>
                        </div>
                        
                    </div>
                    
                  </mat-card-content>
             
            </mat-card> 
        </div>
        <div><br /><br /></div>
        <div class="alinhamento">
            <mat-card class="cardPronto  mat-elevation-z12" >
                <mat-card-header>
                    <div mat-card-avatar class="elianaaa-header-image"></div>
                    <mat-card-title>Eliane Abreu</mat-card-title>
                    
                  </mat-card-header>
                  <img mat-card-image  src="../../../assets/image/elianeaa.jpeg" alt="Wendell">
                  
                  <mat-card-content  >
                    
                    <div class="contCard"> 
                        
                        <div>
        
                            Eliane tem 43 anos, sempre se dedicou ao lar, como dona de casa, mãe e esposa! <br />
                            Hoje com seus muito bem criados decidiu se priorizar e definir um novo rumo para sua 
                            história! Ela acredita muito que a tecnologia está sendo uma área de muitas oportunidades e 
                            acolhendo muitas pessoas como ela, que busca uma nova vida através de tecnologia e conhecimento.<br />
                            Recentemente foi aprovada no curso técnico no Centro Paula Souza (ETEC SP) em ADS.<br />
                            Apesar de já ter superado algumas barreiras e já estar investindo tempo nessa transformação com muita dedicação, um equipamento ainda é uma barreira.<br />
                            Espero que esse gesto possa ajudá-la a impulsionar ainda mais tudo que já conquistou mesmo sem os recursos necessários!

                            <br /><br />
                            <b>links: </b><br />
                            Instagram Eliane: <a href="https://www.instagram.com/elianeaasantos/" target="_blank">elianeaasantos </a> <br />
                            
                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:6949708545588109312?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A6949708545588109312%29" target="_blank"> Post anúncio Linkedin </a> <br />
                            
                        </div>
                        
                    </div>
                    
                  </mat-card-content>
             
            </mat-card> 
        </div>
        <div><br /><br /></div>
        <div class="alinhamento">
            <mat-card class="cardPronto  mat-elevation-z12" >
                <mat-card-header>
                    <div mat-card-avatar class="jamile-header-image"></div>
                    <mat-card-title>Jamile</mat-card-title>
                    
                  </mat-card-header>
                  <img mat-card-image  src="../../../assets/image/jamile.jpg" alt="Lais Lima">
                  
                  <mat-card-content>
                    <div class="contCard"> 
                        <div>   <br />            
                            Jamili tem 27, mora em Salvador – Bahia, tem um filho pequeno com autismo e se encontra desempregada no momento! Acredita muito que tecnologia pode ser uma forma de transformar sua vida, assim como os estudos! <br />
                            Tem sofrido muito com ansiedade e falta de oportunidades no mercado, além de não possuir hoje condições de ter um equipamento que possa proporcionar seus estudos e investimento na tecnologia.<br />
                            Espero que com essa doação do KitDev e acompanhamento de carreira seja possível se dedicar cada vez mais aos estudos e mudar sua realidade!<br />

                            <br /> <br /> <br /> 
                            <b>links: </b><br />
                            Instagram Mili: <a href="https://www.instagram.com/mili_freitas" target="_blank">mili_freitas</a> <br />
                            <a href="https://www.linkedin.com/in/jamile-freitas-72a23220a/" target="_blank"> Linkedin  </a><br />
                            <a href="https://www.linkedin.com/posts/jamile-freitas-72a23220a_gratidaeto-activity-6997564692965511168-6ylJ?utm_source=share&utm_medium=member_desktop" target="_blank"> Post anúncio Linkedin </a> <br />
                            <a href="https://www.instagram.com/p/Ck552_prgaE/?utm_source=ig_web_copy_link   " target="_blank">Post anúncio Instagram</a>
                        </div>
                    </div>
                  </mat-card-content>
            </mat-card> 
        </div>
    </div>

    <br /><br />

    <div class="postsOrg">
        <div class="alinhamento">
            <mat-card class="cardPronto  mat-elevation-z12" >
                <mat-card-header>
                    <div mat-card-avatar class="tami-header-image"></div>
                    <mat-card-title>Tamires Dib Rodrigues</mat-card-title>
                    
                  </mat-card-header>
                  <img mat-card-image  src="../../../assets/image/tamires.jpg" alt="Tamires Dib">
                  
                  <mat-card-content  >
                    
                    <div class="contCard"> 
                        
                        <div>
                            Tamires tem 20 anos, moradora do Brás – SP, durante a pandemia foi desligada da sua 
                            empresa aonde atuava como operadora de telemarketing. <br />
                            Nesse tempo começou um bootcamp de UX e desenvolvimento web além de já estar cursando ADS. 
                            Ela vem desenvolvendo muito seu gosto pela tecnologia e trabalhando muito para ter uma condição melhor
                             através do conhecimento e estudos! Mesmo começando à tão pouco tempo na tecnologia, ela vem compartilhando
                              seu aprendizado na internet e sua jornada. <br />
                                Espero que a doação do kit e todo acompanhamento ajude à impulsionar seus estudos, carreira e também à influenciar e impulsionar a vida de outras como ela que acreditam que podem conquistar tudo independentemente das adversidades!

                            
                            <br /><br /><br /> 
                            <b>links: </b><br />
                            Instagram: 
                            <a href="https://www.instagram.com/tamires.dib/" target="_blank"> tamires.dib</a> <br />
                            
                            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7013268156383145984?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7013268156383145984%29" target="_blank"> Post Anúncio Linkedin </a> <br />
                            <a href="https://www.instagram.com/p/Cmpay2FLFG4/?utm_source=ig_web_copy_link" target="_blank">Instagram</a>
                        </div>
                        
                    </div>
                    
                  </mat-card-content>
             
            </mat-card> 
        </div>
    </div>

</div>