
import { MenuService } from './menu.service';
import { Modulos } from './modulos.model';
import { Aulas } from './Aulas.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { Subscription } from 'rxjs';
import { browserRefresh } from '../../app.component';


@Component({
  selector: 'app-trilha',
  templateUrl: './trilha.component.html',
  styleUrls: ['./trilha.component.css']
})

export class TrilhaComponent implements OnInit, OnDestroy {
  aulas: Aulas[]
  menulateral: Modulos[]
  aulaselecionada: Aulas
  carregando
  browserRefresh
  private sub: any; //controla Subscribe da mudança de ID
  subscription: Subscription;
  constructor(private route: ActivatedRoute,
              private router: Router,
              private menutrilha: MenuService) { 
                router.events.subscribe(event => {
                  if (event instanceof NavigationEnd) {
                    this.ngOnInit();
                  }
                  // Instance of should be: 
                  // NavigationEnd
                  // NavigationCancel
                  // NavigationError
                  // RoutesRecognized
                });
              }
          
  ngOnInit(): void {
    this.browserRefresh = browserRefresh;
    console.log('refreshed?:', browserRefresh);
    if(browserRefresh){
      console.log("refresh")
      //this.menutrilha.clear()
      this.router.navigateByUrl('menutrilha')
      this.menutrilha.clear()
      
    }else{
       
        let id = this.route.snapshot.paramMap.get('aulaID')
        this.menulateral = this.menutrilha.getMenu()
        //console.log(this.menulateral)
        if(this.menulateral == undefined){
          
          this.carregamenu()
          
        } else {
        this.aulas = this.menutrilha.getAulas()
        this.aulaselecionada = this.menutrilha.getAulaSelecionada()
        this.sub = this.route.params
              .subscribe(params => {
                // get id from params
                let idn = params['aulaID'];
                if (params.aulaID != undefined){ //caso tenha selecionado 
                  if(isNaN(idn)){
                    this.aulaSelecionada(idn.toString())
                  }else{
                    this.aulaSelecionada(idn)
                  }
                  
                }else{
                  //console.log("certo: " + this.aulas[0].aulaID)
                  this.aulaSelecionada(this.aulas[0].aulaID)
                }
                //this.carregando = false
              })
      }
    }
    
    
  }
  
  download(){
    //console.log(this.aulaselecionada)
    var url= this.aulaselecionada.material 
    window.open(url, 'Download');  
  }

  step = 0;
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  initAula(){

  }
  aulaSelecionada(idaula: string){
    //this.carregando=true
    //console.log("imprimindo ID")
    //console.log(idaula)
    //seleciona objeto com informaçoes da aula selecionada
    //let aulaselecionada: Aulas
    if (this.aulas != undefined){
      console.log("aulas estava defined")
      //console.log(this.aulas)
      this.aulaselecionada = this.aulas.find(e => e.aulaID == idaula)
      //seta a aula seleciona no componente
      //console.log(this.aulaselecionada.videoID)
      this.menutrilha.setAulaSelecionada(this.aulaselecionada)
      this.carregando = false
    }else{
      
      this.menutrilha.clear()
      this.carregamenu()
      setTimeout(() => {
        console.log("aulas estava undefined")
        //console.log(this.aulas)
        this.aulaselecionada = this.aulas.find(e => e.aulaID == idaula)
        //seta a aula seleciona no componente
        //console.log(this.aulaselecionada.videoID)
        this.menutrilha.setAulaSelecionada(this.aulaselecionada)
        
      },7001)
      
    }
    
  }
  carregamenu(){
    this.carregando=true
    //carregar menus via serviço
    if (this.aulas == null){
        
      this.menutrilha.carregarMenu();
      setTimeout(() => {
        this.aulas = this.menutrilha.getAulas()
        //console.log("imprimindo aulas")
        //console.log(this.aulas )

        //setTimeout(() => {
          this.menulateral = this.menutrilha.getMenu()
          this.sub = this.route.params
          .subscribe(params => {
            // get id from params
            let idn = +params['aulaID'];
            //console.log(params.aulaID)
            if (params.aulaID != undefined){
              //console.log("Zuado")
              this.aulaSelecionada(idn.toString())
            // do whatever you want with id here
            }else{
              //console.log("certo")
              this.aulaSelecionada(this.aulas[0].aulaID)
            }
            //this.carregando = false
          });
        //},2000)
        this.carregando=false
      },6000)
      
    }
    
  }
  // unsubscribe to avoid memory leaks
  ngOnDestroy() {
  
    if(this.sub){
      //console.log("destruiu")
      this.sub.unsubscribe();
      
    }
    
  }

}
