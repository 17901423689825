import { SimuladoService } from './simulado.service';
import { Questoes } from './questoes.model';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-simulado',
  templateUrl: './simulado.component.html',
  styleUrls: ['./simulado.component.css']
})
export class SimuladoComponent implements OnInit {

  questoes: Questoes[]
  questaoSelecionada: Questoes
  resps: string[] = ['Verdadeiro', 'Falso'];
  resposta: string
  respostaValidada: boolean
  validacao: any
  carregando: boolean
  qtdquestoes: number
  qtdacertos = 0 //Controla Quantas Questões o usuário acertou
  atual = 1
  encerrado = false
  contador = 0 //contador usado para validar quantas respostas foram selecionadas
  constructor(private simulado: SimuladoService) { }

  ngOnInit(): void {
    this.respostaValidada = false
    this.resposta = null
    this.validacao = null
    this.carregando = true
    this.simulado.buscaQuestoesPractitioner()
    setTimeout(() => {
    this.questaoSelecionada  = this.simulado.getItem(0)
    this.carregando = false
    this.qtdquestoes = this.simulado.getQtdItems()
    }, 5500)
  }

  checkboxSelecionado(alternativa: any){
    //contador para habilitar ou não a validação da resposta
    //pelo menos um checkbox deve estar selecionado
    console.log(alternativa)
    if (!alternativa.selecionado){
      this.contador --
    }else{
      this.contador ++
    }
    console.log(this.contador)
  }

  radioSelecionado(alternativa: any){
    this.resposta = alternativa //selecionado via radio button
  }


  validarResposta(form: NgForm){
  
    //para questões do tipo vernadeiro/falso e alternativas retorna validação simples de seleção
    if(this.questaoSelecionada.tipo == "1"|| this.questaoSelecionada.tipo == "2"){
      //validação inicia com valor verdadeiro, se encontrar alguma resposta inválida, a resposta se torna falsa  
      this.validacao = false 
        this.questaoSelecionada.alternativas.forEach((alt)=>{
          if (alt.resposta && alt.alt == this.resposta){
            this.validacao = true //caso a resposta selecionada seja a verdadeira
          }
        })
    }else if(this.questaoSelecionada.tipo == "3"){
      this.validacao = true //inicialmente a validação é falsa, pois o loop é pra encontrar se a resposta selecionada seria a correta
      this.questaoSelecionada.alternativas.forEach((alt)=>{
        if (alt.resposta != alt.selecionado){
          this.validacao = false //caso haja qq resposta inválida, perde toda questão
        }
      })
    }
    if(this.validacao){
      this.qtdacertos ++
      console.log("Acertos: " + this.qtdacertos)
    }
    this.respostaValidada = true
    //libera próxima questão
  }


  onSubmit(form: NgForm):void{
    this.respostaValidada = false
    this.resposta = null
    this.validacao = null
    this.carregando = true
    this.atual ++ //próxima questão
    if ( this.atual <= this.qtdquestoes){ //navega até a ultima questão
     this.questaoSelecionada  = this.simulado.getItem(this.atual - 1)
    } else { 
      //encerra simulado
      this.encerrado = true
    }
    this.carregando = false


  }

}
