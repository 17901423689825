
<div class="alinhamento" *ngIf="!carregando"> 
    <mat-card class="card mat-elevation-z12">
        <mat-card-title>
            Código de Confirmação
        </mat-card-title>
        <mat-card-subtitle>
            * Obs: Você receberá um código de confirmação em seu e-mail. Caso demore verifique em sua caixa de SPAM
        </mat-card-subtitle>
    <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)">
        <mat-form-field>
            <input matInput placeholder="Usuário" ngModel
                name="email" required >
        </mat-form-field>
        <mat-form-field>
            <input matInput 
                   placeholder="Senha" ngModel
                   name="senha" required 
                   minlength="6" type="password"> 
        </mat-form-field>
        <mat-form-field>
            <input matInput 
                   placeholder="Código de Confirmação" ngModel
                   name="codigo" required 
                   minlength="4" type="text"> 
        </mat-form-field>
     
    <button mat-raised-button
    color="primary" type="submit"
    [disabled]="!authForm.valid">
     Confirmar Usuário
    </button>
    </form>
    </mat-card>
    </div>
    <div *ngIf="carregando" >
        <p class="spinnertrilha"><mat-spinner color="accent" ></mat-spinner></p>
        
    </div>