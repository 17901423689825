import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-criador',
  templateUrl: './criador.component.html',
  styleUrls: ['./criador.component.css']
})
export class CriadorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
