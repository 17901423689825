import { Subscription } from 'rxjs';
import { Aulas } from './../../views/trilha/Aulas.model';
import { ActivatedRoute } from '@angular/router';
import { MenuService } from './../../views/trilha/menu.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-aula',
  templateUrl: './aula.component.html',
  styleUrls: ['./aula.component.css']
})
export class AulaComponent implements OnInit, OnDestroy{

  aulaselecionada: Aulas
  private sub: any; //controla Subscribe da mudança de ID

  constructor(private route: ActivatedRoute,
              private menutrilha: MenuService) { }

  ngOnInit(): void {

    // This code loads the IFrame Player API code asynchronously, according to the instructions at
    // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
    const tag = document.createElement('script');
    tag.src = "https://youtube.com/iframe_api";
    tag.id = "youtubeScript";
    
    //document.headers.set("Origin","http://localhost:4200/")
    //tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);

    //subscribe para pegar as alteraçoes de parametro da URL
    this.sub = this.route.params
    .subscribe(params => {
      // get id from params
      //console.log(window.location.host)
      let idn = +params['aulaID'];
      this.aulaselecionada = this.menutrilha.getAulaSelecionada()

    });

  }
  // unsubscribe to avoid memory leaks
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
