
import { BehaviorSubject, Subscription } from 'rxjs';
import { Auth } from './../auth.model';

import { AuthService } from './../auth.service';
import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit  {
  carregando
  constructor(private auth: AuthService,
             private router: Router,
             ) { }
  
  subscription: Subscription
  authObj: Auth           
  ngOnInit(): void {
    this.carregando = false
    this.authObj = new Auth()
    this.subscription = this.authObj.getValue().subscribe((valor)=>{
      //console.log(valor)
      if (valor){
        this.router.navigate(['/authnew'])
      }
    })
  }
  
  ngOnDestroy():void{
    this.subscription.unsubscribe()
  }
  onSubmit(form: NgForm):void{
    this.carregando = true
   
    this.auth.setAuth(this.authObj) 
    var login = this.auth.efetuaLogin(form.value.email, form.value.senha, null) 
    setTimeout(()=>{

      if(this.auth.user.value != null){
        console.log("logado")
        this.router.navigate(['/certi'])
       
      }else{
        form.value.email = ""
        form.value.senha = ""
        this.carregando = false
      }
    }, 5000)
    
  }

  onRegistrar():void{
    this.router.navigate(['/registrar'])
  }
  onConfirmar():void{
    this.router.navigate(['/codigo'])
  }
  onReset():void{
    this.router.navigate(['/reset'])
  }
}
