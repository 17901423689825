import { ActivatedRoute } from '@angular/router';

import { Component, OnInit } from '@angular/core';
//import { simulado } from './simulado/simulado.component'

@Component({
  selector: 'app-certificacoes',
  templateUrl: './certificacoes.component.html',
  styleUrls: ['./certificacoes.component.css']
})
export class CertificacoesComponent implements OnInit {
  private sub: any; //controla Subscribe da mudança de ID
  menu: number //0: menu - 1: practitioner
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    //this.simulado = new SimuladoComponent()
    this.menu = 0
    this.sub = this.route.params
    .subscribe(params => {
      // get id from params
      let idn = +params['certi'];
      console.log(idn)
      if (idn >= 0 ){
        //inicia simulado de practitioner
        this.menu = idn
      }
      
    })
    //console.log(this.menu)
  }

}
