<div class="menu">
        

    <mat-card class="cardPronto  mat-elevation-z12">
        
        Treinamento para <br />Certificação Practitioner<br /><br />
        <a routerLink="../trilhas" >
            <img class="in"  src="../../../assets/image/practitioner.png" alt="Practitioner" (click)="settrilha('1')">
        </a>
    </mat-card>

    <mat-card class="cardPronto  mat-elevation-z12">
        
        Treinamento ECS<br /><br />
        <a routerLink="../trilhas" >
            <img class="in"  src="../../../assets/image/ECS_icon.png" alt="Practitioner" (click)="settrilha('2')" >
         </a>
    </mat-card>

</div>