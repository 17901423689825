import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './../../../views/auth/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit, OnDestroy {

  isAuth = false
  private userSub: Subscription
  constructor(private authService: AuthService,
              private router: Router) { }

  ngOnInit(): void {
    this.userSub =  this.authService.user.subscribe(user=>{
      this.isAuth = !user ? false : true
    })
    
  }

  ngOnDestroy():void{
    this.userSub.unsubscribe()
  }

  onLogout(){
    this.authService.logout()
    this.router.navigate(['auth'])
  }
  
}
