<mat-drawer-container class="example-container" autosize>
    <mat-drawer #drawer class="example-sidenav" mode="side">
      

        <div class="header">
            <span class="title-group">
                <a routerLink="/proposito" (click)="drawer.toggle()">
                    <i class="material-icons">
                        emoji_objects
                    </i>
                    Propósito
                </a>
            </span>
            <span class="title-group">
                <a routerLink="criador" (click)="drawer.toggle()">
                    <i class="material-icons">
                        person
                    </i>
                    Criador
                </a>
            </span>
            <span class="title-group" *ngIf="isAuth">
                <a routerLink="menutrilha" (click)="drawer.toggle()">
                    <i class="material-icons">
                        school
                    </i>
                    Trilhas
                </a>
            </span>
            <span class="title-group" *ngIf="isAuth">    
                <a routerLink="certi" (click)="drawer.toggle()">
                    <i class="material-icons">
                        verified 
                    </i>
                    Simulados
                </a>
            </span>
            <span class="title-group" *ngIf="!isAuth">    
                <a routerLink="auth" (click)="drawer.toggle()">
                    <i class="material-icons">
                        login
                    </i>
                    Login
                </a>
            </span>
            <span class="title-group" *ngIf="!isAuth">    
                <a routerLink="registrar" (click)="drawer.toggle()">
                    <i class="material-icons">
                        check_box
                    </i>
                    Registrar-se
                </a>
            </span>
            <span class="title-group" *ngIf="isAuth">    
                <a routerLink="auth" (click)="onLogout()">
                    <i class="material-icons">
                        highlight_off
                    </i>
                    Logout
                </a>
            </span>
        </div>

    </mat-drawer>
  
    
    <a (click)="drawer.toggle()" > 
        <i class="menubt material-icons">
            menu
        </i>
    
    </a>
    
  
  </mat-drawer-container>