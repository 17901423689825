
<div class="alinhamento" *ngIf="!carregando"> 
    <mat-card class="card mat-elevation-z12">
        <mat-card-title>
            Recuperar senha
        </mat-card-title>
        <mat-card-subtitle>
            
            Passo 1: Digite seu e-mail e Clique em "Enviar Codigo" para receber um código de segurança em seu e-mail. <br />
            Passo 2: verifique sua caixa de entrada para pegar o código de segurança <br />
            Passo 3: digite sua nova senha jutanmente com código de segurança e confirme o usuário <br />
            * Obs: Caso demore para receber o código verifique em sua caixa de SPAM <br />
        </mat-card-subtitle>
    <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)">
        <mat-form-field>
            <input matInput placeholder="Usuário" ngModel
                name="email" required >
        </mat-form-field>
        <mat-form-field>
            <input matInput 
                   placeholder="Senha" ngModel
                   name="senha" required 
                   minlength="6" type="password"> 
        </mat-form-field>
        <mat-form-field>
            <input matInput 
                   placeholder="Códico de Confirmação" ngModel
                   name="codigo" required 
                   minlength="4" type="text"> 
        </mat-form-field>
    <button mat-raised-button type="button" (click)="onRegistrar(authForm)">
    Enviar Código
    </button>
    <button mat-raised-button
    color="primary" type="submit"
    [disabled]="!authForm.valid">
     Confirmar Usuário
    </button>

  

    </form>
    </mat-card>
    </div>
    <div *ngIf="carregando" >
        <p class="spinnertrilha"><mat-spinner color="accent" ></mat-spinner></p>
        
    </div>