import { Auth } from './../auth.model';
import {  Subscription } from 'rxjs';
import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-authnew',
  templateUrl: './authnew.component.html',
  styleUrls: ['./authnew.component.css']
})
export class AuthnewComponent implements OnInit {

  constructor(private auth: AuthService, 
              private router: Router) { }
 
          
  ngOnInit(): void {

    //console.log("Cristroxa")
    //console.log(this.auth.getAuth())
    
  }

  onSubmit(form: NgForm):void{
    //console.log(form.value)
    this.auth.efetuaLoginNovaSenha(form.value.email, 
                                  form.value.senha, 
                                  form.value.senhareq,
                                  form.value.senhareq2,
                                  this.auth.getAuth())
  }


}
