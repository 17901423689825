<div class="alinhamento">
    
        

    
    <mat-card class="cardPronto  mat-elevation-z12" >
        <mat-card-header>
            <div mat-card-avatar class="example-header-image"></div>
            <mat-card-title>Cristiano Pelizzari de Andrade</mat-card-title>
            <mat-card-subtitle>Engenheiro de Software</mat-card-subtitle>
          </mat-card-header>
          <img mat-card-image  src="../../../assets/image/criador.jpeg" alt="Cristiano Pelizzari">
          
          <mat-card-content  >
            
            <div class="contCard"> 
                <div class="icones">
                    <div  class="icone">    
                    <a href="https://www.linkedin.com/in/cristianopelizzari/?locale=en_US" target="_blank">
                        <img class="in"  src="../../../assets/image/in.png" alt="linkedin" >
                    </a>
                    </div>
                    <div  class="icone"> 
                    <a href="https://www.youtube.com/crischair" target="_blank">
                    <img class="in"  src="../../../assets/image/yo.png" alt="youtube" height="40px">
                     </a>
                    </div>
                    <div  class="icone"> 
                    <a href="https://www.instagram.com/crischair/" target="_blank">
                    <img class="in"  src="../../../assets/image/insta.png" alt="instagram">
                    </a>
                    </div>
                    <div  class="icone"> 
                        <a href="mailto:cristianopelizzari@gmail.com" target="_blank">
                        <img class="in"  src="../../../assets/image/gmail.png" alt="email">
                        </a>
                    </div>
                </div>
                <div>
                    <br />
                    Engenheiro de software entusiasta em cloud computing, com mais de 15 anos de experiência na área de tecnologia, 
                    atuo em equipes globais multiculturais e multidisciplinares. Experiência em implementações em ambientes corporativos e nuvem AWS.<br />
                    Acredito que a única forma de mudarmos nossa realidade é por meio do conhecimento, motivo pelo qual criei este espaço. <br />
                    O objetivo é compartilhar minha experiência e conhecimento na área de tecnologia, com conteúdos atualizados e em português. 
                    Sonho grande é poder proporcionar cada vez mais diversidade e inclusão no mercado de tecnologia.<br />
                    Faça o cadastro no portal para acessar o conteúdo
                    <br /><br />
                    <div class="centro">
                    <b>Principais Linguagens e Tecnologias:</b><br />
                    ABAP - JavaScript - TypeScript - React - Angular <br />
                    SAP - SAP Cloud Plataform - AWS 
                    <br /><br />
                    <b>Experiência em Metodologias/Ferramentas:<br /></b>
                    Ágil - Cascata - PDCA - Lean Inception<br /><br />
                    <b>Certificações:</b> <br />
                    AWS Solutions Architect Professional <br />
                    AWS Solutions Architect Associate <br />
                    AWS Developer Associate<br /> 
                    SAP Financial Accounting
                    </div>
                </div>
            
            </div>
            
          </mat-card-content>
     
    </mat-card> 
    
    
    
</div>