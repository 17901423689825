import { User } from './auth/user.model';
import * as AmazonCognitoIdentity  from 'amazon-cognito-identity-js';
import { Auth } from './auth.model';
import { Injectable, OnInit } from '@angular/core';
import { CognitoUser, AuthenticationDetails, CognitoUserPool } from "amazon-cognito-identity-js";
import {MatSnackBar} from '@angular/material/snack-bar';
import {  BehaviorSubject } from 'rxjs';
//import { Observable,  BehaviorSubject } from 'rxjs';
//import { userInfo } from 'os';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  //controle de alteração de usuário
  user = new BehaviorSubject<User>(null)
  constructor(private snackBar: MatSnackBar) { 
  }
  

  private authObj: Auth   
  att  = []

  //exibe mensagens 
  showMessage(msg: string, isError: boolean = false): void {
    this.snackBar.open(msg, 'X', {
      duration: 7000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
      panelClass: isError ? ['msg-error'] : ['msg-success']
    })
  }

  setAuth(auth: Auth){
    this.authObj = auth
  }
  getAuth(): Auth{
    return this.authObj
  }

  //Efetua logout, apaga os dados do usuário da memória e do local storage
  logout(){
    this.user.next(null)
    localStorage.removeItem('userData')
  }

  resetPassword(username:string, codigo:string, senha:string){

    this.authObj = new Auth
    const user = new CognitoUser({
      Username: username,
      Pool: this.authObj.getpoolData()
    });
    
    // call forgotPassword on cognitoUser
    user.confirmPassword(codigo, senha, {
      onSuccess: () => {
        console.log("Sucesso: Senha atualizada ");
        this.showMessage("Senha atualizada", false);
        
      },
      onFailure: err => {
        console.error("Erro :", err);
        this.showMessage(err.message, true);
      }
    });
}

enviarCodigo(username:string){

  this.authObj = new Auth
  const user = new CognitoUser({
    Username: username,
    Pool: this.authObj.getpoolData()
  });
  
  // envia código para reestabelecer senha
  user.forgotPassword({
    onSuccess: data => {
      console.log("onSuccess:", data);
      this.showMessage("Código enviado por email", false);
    },
    onFailure: err => {
      console.error("onFailure:", err);
      this.showMessage(err.message, true);
    },
    inputVerificationCode: data => {
      console.log("Input code:", data);
      this.showMessage("Código enviado por email", false);
      
    }
  });
}

  //função que mantem o usuário logado automaticamente com refreshToken
  autoRefresh(tempo: number, user: CognitoUser, refreshToken: AmazonCognitoIdentity.CognitoRefreshToken){
    setTimeout(()=>{
      user.refreshSession(refreshToken, (err, session) => {
        if (err) throw err;

        //console.log(session.getIdToken().getJwtToken())
        const expirationDate = new Date(session.getIdToken().getExpiration() * 1000)
        const expTime = session.getIdToken().decodePayload().exp - session.getIdToken().decodePayload().auth_time

         //carrega dados do usuário
        this.handleAuth(
          session.getIdToken().decodePayload().email, 
          session.getIdToken().decodePayload().username,
          session.getIdToken().getJwtToken(),
          expirationDate,
          session.getRefreshToken(),
          expTime,
          user
         ) 
      })
   // },(10000))
    },(tempo * 500))
  } //fim refresh token

  preparaAtributos(nome:string,valor:string):void{
    let atttemp = new AmazonCognitoIdentity.CognitoUserAttribute({
      Name: nome ,
      Value: valor
    })
    //att.push(attuser)     
    this.att.push(atttemp) 
  }

  //Função de Registro de Usuários no Cognito
  registrarUsuario(email:string, 
                  senha1: string, 
                  senha2: string,
                  usuario: string,
                  nome: string,
                  sobrenome: string,
                  authObj: Auth){
     
      if (this.att.length == 0){
      //função que inclui os atributos do usuário
      this.preparaAtributos("email", email)
      this.preparaAtributos("family_name" , sobrenome)
      this.preparaAtributos("gender", "Male")
      this.preparaAtributos("name", nome)
    }
    if(senha1 != senha2){
      this.showMessage("As Senhas devem ser idênticas", true)
      
    }else{
                              
     
      //cadastra usuário novo na base
      const UserPool = authObj.getpoolData();  
      //console.log(UserPool.getUserPoolId())
      //metodo de registrar o usuário
      UserPool.signUp( usuario, senha1, this.att, null, (err, data) => {
        if (err) {
          //console.error(err);
          this.showMessage(err.message, true)
        } else{
          //console.log(data);
          this.showMessage("Usuário Criado Com sucesso!", true)
          authObj.setConfirm(true)
        }
        
      }) ;     
    }}

  /*se o usuário atualizar a página, recupera o token do local storage
    para não perder o token do login*/
  autoLogin(){

    const userData: {
       email: string,
       id: string,
       _token: string,
       _tokenExpDate: Date,
       _refreshToken
    } = JSON.parse( localStorage.getItem('userData') )
    if (!userData){
      return
    }
    const loadedUser = new User(
          userData.email,
          userData.id,
          userData._token,
          new Date(userData._tokenExpDate),
          userData._refreshToken
      )
      if(loadedUser.token){
        this.user.next(loadedUser)
      }
  }
  //gerencia dados de autenticação
  handleAuth(email:string,
            username: string,
            token: string,
            expDate: Date,
            refreshToken: AmazonCognitoIdentity.CognitoRefreshToken,
            expTime: number,
            userpar: CognitoUser){
    const user = new User(
      email, 
      username,
      token,
      expDate,
      refreshToken.getToken())
      this.user.next(user)
      //guarda o token no localstorage para o caso do usuário
      //atualizar a página e não perder o token
      localStorage.setItem('userData', JSON.stringify(user))
      this.autoRefresh(expTime, userpar, refreshToken)
  }

  //Login padrão
  async efetuaLogin(email:string, senha: string, codigo: string):Promise<boolean>{
    this.authObj = new Auth
    const user = new CognitoUser({
      Username: email,
      Pool: this.authObj.getpoolData()
    });
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: senha
    });
    //caso precise confirmar o usuário no primeiro Login
    if(codigo !== null){
    user.confirmRegistration(codigo, true,(err, data)=>{
      if (err) {
        this.showMessage("Erro Na confirmação do Usuário", true)
        return false;
      }else{
          //console.log(data);
          this.showMessage("Usuário Confirmado Com Sucesso", true)
      }
      
    }) 
    //return true
   } 
  
   //efetua o login
   setTimeout(() => {
    user.authenticateUser(authDetails, {
      onSuccess: data => {

        const expirationDate = new Date(data.getIdToken().getExpiration() * 1000)
        const expTime = data.getIdToken().decodePayload().exp - data.getIdToken().decodePayload().auth_time
        
       
        //carrega dados do usuário
        this.handleAuth(
          data.getIdToken().decodePayload().email, 
          data.getIdToken().decodePayload().username,
          data.getIdToken().getJwtToken(),
          expirationDate,
          data.getRefreshToken(),
          expTime,
          user
         ) 
         console.log("logou")
         return true
      },

      onFailure: err => {
       // console.error("onFailure:", err);
        this.showMessage(err.message, true)
        //this.user = new BehaviorSubject<User>(null)
        return false
      },

      newPasswordRequired: (usertt, requiredatt)=>{
        //console.log("newPasswordRequired:", usertt);
        this.showMessage("Nova Senha Requerída", true)
        this.authObj.setValue(true)
        this.authObj.setAT(requiredatt)
        return true
      }
    })
    
  },2500)
  return false
  }

  //Login com alteração obrigatória de senha
  efetuaLoginNovaSenha(email:string, senha: string,senhanova1: string,senhanova2: string, auth: Auth){
    if(senhanova1 !== senhanova2){ //Verifica se a senha foi digitada corretamente nos dois campos de senha
      this.showMessage("Os campos de Nova Senha e Confirmação não são idênticos", true)
    }else if (senhanova1 == senhanova2){
      const user = new CognitoUser({ //Verifica se a senha foi digitada corretamente nos dois campos de senha
        Username: email,
        Pool: auth.getpoolData()
      });
      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: senha
      });
      let atrteste: any
      
      //console.log(auth.getAT())
      user.getUserAttributes(atr=>{
        //console.log(atr.name)
      })
      
      
      user.authenticateUser(authDetails, {
        onSuccess: data => {
          //console.log("onSuccess:", data.getIdToken);
          const expirationDate = new Date(data.getIdToken().getExpiration() * 1000)
          const expTime = data.getIdToken().decodePayload().exp - data.getIdToken().decodePayload().auth_time
          //console.log(expirationDate)
          //carrega dados do usuário logado
          this.handleAuth(
            data.getIdToken().decodePayload().email, 
            data.getIdToken().decodePayload().username,
            data.getIdToken().getJwtToken(),
            expirationDate,
            data.getRefreshToken(),
            expTime,
            user
         ) 
        },

        onFailure: err => {
          //console.error("onFailure:", err);
          this.showMessage(err.message, true)
          //this.user = new BehaviorSubject<User>(null)
        },
  
        newPasswordRequired: (usertt, requiredatt)=>{
          user.completeNewPasswordChallenge(senhanova1,usertt,{
            onSuccess: data => {
              //console.log("onSuccess:", data.getIdToken);
              //console.log("Senha alterada com sucesso")
              const expirationDate = new Date(data.getIdToken().getExpiration() * 1000)
              const expTime = data.getIdToken().decodePayload().exp - data.getIdToken().decodePayload().auth_time
              //console.log(expirationDate)
              //carrega dados do usuário
              this.handleAuth(
                data.getIdToken().decodePayload().email, 
                data.getIdToken().decodePayload().username,
                data.getIdToken().getJwtToken(),
                expirationDate,
                data.getRefreshToken(),
                expTime,
                user
                )
            },
            onFailure: err => {
              //console.error("onFailure:", err);
              this.showMessage(err.message, true)
              //this.user = new BehaviorSubject<User>(null)
            }
          }
        )
        }
      })
      
    }
  }
}
