<mat-toolbar class="header mat-elevation-z12">
    <span class="title-group">
        <a routerLink="proposito">
            <i class="material-icons">
                emoji_objects
            </i>
            Propósito
        </a>
    </span>
    <span class="title-group">
        <a routerLink="criador">
            <i class="material-icons">
                person
            </i>
            Criador
        </a>
    </span>
    <span class="title-group" *ngIf="isAuth">
        <a routerLink="menutrilha">
            <i class="material-icons">
                school
            </i>
            Trilhas
        </a>
    </span>
    <span class="title-group" *ngIf="isAuth">    
        <a routerLink="certi">
            <i class="material-icons">
                verified 
            </i>
            Simulados
        </a>
    </span>
    <span class="title-group" *ngIf="!isAuth">    
        <a routerLink="auth" >
            <i class="material-icons">
                login
            </i>
            Login
        </a>
    </span>
    <span class="title-group" *ngIf="!isAuth">    
        <a routerLink="registrar">
            <i class="material-icons">
                check_box
            </i>
            Registrar-se
        </a>
    </span>
    <span class="title-group" *ngIf="isAuth">    
        <a routerLink="auth" (click)="onLogout()">
            <i class="material-icons">
                highlight_off
            </i>
            Logout
        </a>
    </span>
</mat-toolbar>