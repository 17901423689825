import { stringify } from 'querystring';
import { Modulos } from './modulos.model';
import { Aulas } from './Aulas.model';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http'
import { map } from 'rxjs/operators';
import { stripGeneratedFileSuffix } from '@angular/compiler/src/aot/util';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  aulas: Aulas[];
  menulateral: Modulos[];
  aulaSelecionada: Aulas;
  trilha;
  idtrilha ; //trilha default
  refresh = false;
  
  url = "https://tcio8m8159.execute-api.sa-east-1.amazonaws.com/prod/"
  constructor(private http: HttpClient) { }

   carregarMenu(){
     
    //carrega menulateral
    //console.log("chamando: " + this.idtrilha)
    this.http.get( this.url + "trilha",{headers: new HttpHeaders({
      'Content-Type':  'application/json',
      tabela: 'trilha',
      trilha: this.idtrilha 
    })})
             .pipe(map((reponseData: {body: {modulos:Object, aulas: Aulas[]}})=>{
                const postArray = []
                for (const key in reponseData.body.modulos){
                  if (reponseData.body.modulos.hasOwnProperty(key)){
                    postArray.push({...reponseData.body.modulos[key], modulo: key})
                  }
                }
                //console.log(reponseData.body)
                this.trilha = reponseData.body.aulas
                return postArray
             }))
             .subscribe( 
                response => {
                  //console.log(response)
                  this.menulateral = response

                  //movido
                  //console.log(this.trilha)
                  //prepara os parametros da chamada get com as aulas selecionadas
                  let paramsAulas = new HttpParams()
                  this.trilha.forEach(aula=>{
                    paramsAulas = paramsAulas.append("aulas",aula)
                  })
                  //carrega aulas do menu
                  this.http.get(this.url + "aulas/",{params: paramsAulas,

                  })
                  .pipe( map((reponseData: {aulas: Aulas[]})=>{
                      const postArray = []
                      for (const key in reponseData.aulas){
                        if (reponseData.aulas.hasOwnProperty(key)){
                          postArray.push({...reponseData.aulas[key]})
                        }
                      }

                      //console.log(postArray)
                      this.sort_by_key(postArray, 'aulaID')
                      return postArray.sort()
                  }))
                  .subscribe( 
                      response => {
                        //console.log(response)
                        //console.log(response)
                        this.aulas = response
                      }   
                  )
                }   
             )  
  }

  sort_by_key(array, key)
  {
   return array.sort(function(a, b)
   {
    var x = a[key]; var y = b[key];
    return ((parseInt(x) < parseInt(y)) ? -1 : ((parseInt(x) > parseInt(y)) ? 1 : 0));
   });
  }

  //Buscar cadastro de aulas
  getAulas(){
    return this.aulas
  }

  //Buscar Menu
  getMenu(){
    return this.menulateral
  }

  setAulaSelecionada(aula: Aulas): void{
    this.aulaSelecionada = aula
    //console.log("Setting Aula Selecionada")
    //console.log(aula.videoID)
  }
  getAulaSelecionada(){
      return this.aulaSelecionada
  }
  setIdTrilha(id:string){
    this.idtrilha = id;
    this.clear()
  }

  clear(){
    this.aulas = this.menulateral = this.aulaSelecionada = undefined
  }

}
