
<div class="alinhamento" *ngIf="!carregando"> 
    <mat-card class="card mat-elevation-z12">
        <mat-card-title>
            Registrar-se
        </mat-card-title>
        Insira um email válido <br />
        Sua senha deve possuír pelo menos 6 caracteres, com pelo menos uma letra maiúscula, pelo menos uma letra minúscula e um caracter especial <br />
        Após o Cadastro você irá receber um código de verificação por e-mail, confirme seu cadastro utilizando esse código!
        
    <form #authFormreg="ngForm" (ngSubmit)="onSubmit(authFormreg)">
        <mat-form-field>
            <input matInput placeholder="E-mail" ngModel
                name="email" required >
        </mat-form-field>

        <mat-form-field>
            <input matInput 
                   placeholder="Usuário" ngModel
                   name="usuario" required 
                   minlength="3" type="text"> 
        </mat-form-field>

        <mat-form-field>
            <input matInput 
                   placeholder="Nome" ngModel
                   name="name" required 
                   minlength="2" type="text"> 
        </mat-form-field>

        <mat-form-field>
            <input matInput 
                   placeholder="Sobrenome" ngModel
                   name="family_name" required 
                   minlength="2" type="text"> 
        </mat-form-field>


        <mat-form-field >
            <input matInput 
                placeholder="Nova Senha" 
                ngModel required
                name="senhareq"  
                minlength="6" type="password"> 
        </mat-form-field>
        
        <mat-form-field >
            <input matInput required
                placeholder="Nova Senha Confirmação" 
                ngModel 
                name="senhareq2"  
                minlength="6" type="password"> 
        </mat-form-field>

       
    <button mat-raised-button
    color="primary" type="submit"
    [disabled]="!authFormreg.valid">
     Registrar-se
    </button>
    
    </form>
    </mat-card>
    </div>

    <div *ngIf="carregando" >
        <p class="spinnertrilha"><mat-spinner color="accent" ></mat-spinner></p>
        
    </div>