import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proposito',
  templateUrl: './proposito.component.html',
  styleUrls: ['./proposito.component.css']
})
export class PropositoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
