
<div class="alinhamento"> 
    
    <mat-card class="card mat-elevation-z12" > 
        <div  *ngIf="!encerrado" >
            
            <div *ngIf="carregando" >
                <p class="spinnertrilha"><mat-spinner color="accent" ></mat-spinner></p>
            </div>
            <mat-card-title *ngIf="!carregando" class="titulo">
                {{questaoSelecionada.texto_en}} <br />
            </mat-card-title>
            <mat-card-subtitle *ngIf="!carregando">
                {{questaoSelecionada.texto_pt}}
            </mat-card-subtitle>
                
            <form #questForm="ngForm" (ngSubmit)="onSubmit(questForm)" *ngIf="!carregando">
                <section >
                    
                    <div 
                    *ngIf="questaoSelecionada.tipo == '1' || questaoSelecionada.tipo == '2'">
                        <mat-radio-group
                        
                        class="example-radio-group"
                        name="resposta">
                        
                        <mat-radio-button class="example-radio-button" 
                                            *ngFor="let alternativa of questaoSelecionada.alternativas" 
                                            [value]="alternativa"
                                            [disabled]="respostaValidada"
                                            (change)="radioSelecionado(alternativa.alt)"
                                            >
                        {{alternativa.alt}} <br />
                        </mat-radio-button> <br />
                        </mat-radio-group>
                        
                    </div>
                </section> <br />
                    
                <div *ngIf="questaoSelecionada.tipo == '3'">
                    <section class="example-section">
                        <mat-checkbox 
                                        *ngFor="let alternativa of questaoSelecionada.alternativas" 
                                        [(ngModel)]="alternativa.selecionado"
                                        [ngModelOptions]="{standalone: true}"
                                        (change)="checkboxSelecionado(alternativa)"
                                        [disabled]="respostaValidada"
                                        >
                        {{alternativa.alt}} <br />
                        </mat-checkbox>

                        </section><br />
                </div>

                <mat-card class="respostacorreta" 
                        *ngIf="validacao">
                    Parabéns, Resposta Correta! <br />
                    {{questaoSelecionada.desc}}
                </mat-card>
                <mat-card class="resposterrada"
                            *ngIf="validacao == false">
                    Resposta Errada!<br />
                    {{questaoSelecionada.desc}}
                </mat-card>
            
                <button mat-raised-button
                        color="primary" type="submit"
                        [disabled]="!respostaValidada"
                        >
                    Próxima questão
                </button>

                <button mat-raised-button type="button"
                        (click)="validarResposta(questForm)"
                        [disabled]="!resposta || respostaValidada"
                        *ngIf="questaoSelecionada.tipo == '1' || questaoSelecionada.tipo == '2'"
                >
                Validar resposta
                </button>
        

                <button mat-raised-button type="button"
                        (click)="validarResposta(questForm)"
                        [disabled]="!(contador > 0) || respostaValidada"
                        *ngIf="questaoSelecionada.tipo == '3'"
                >
                    Validar resposta
                </button>

            </form>
            <mat-card-footer class="footer"> {{atual}} / {{qtdquestoes}}</mat-card-footer>
        </div>        
        <div  *ngIf="encerrado" class="encerrado">
            
           <h1> Você concluíu o Simulado 
            <mat-icon aria-hidden="false" 
                      inline=true 
                      style="-webkit-text-fill-color:darkgreen" 
                      aria-label="Example home icon">flag</mat-icon></h1><br />
            Quantidade de Questões respondidas: {{qtdquestoes}} <br />
            Quantidade de Respostas Corretas: {{qtdacertos}} <br />
            Percentual de Acertos: {{qtdacertos / qtdquestoes | percent:'1.2-2' }}
        </div>
    </mat-card>
    </div>