import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Auth } from '../auth.model';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {

  authObj: Auth
  carregando
  constructor(private auth: AuthService,
              private router: Router) { }

  subscription: Subscription
          
  ngOnInit(): void {
    this.carregando = false
    this.authObj = new Auth()
    this.subscription = this.authObj.getValue().subscribe((valor)=>{
      //console.log(valor)
      if (valor){
        this.router.navigate(['/authnew'])
      }
    })
  }
  ngOnDestroy():void{
    this.subscription.unsubscribe()
  }
  onSubmit(form: NgForm):void{
    this.carregando = true
    //this.auth.setAuth(this.authObj) 
    //console.log(form.value)
    //var login = this.auth.resetPassword(form.value.email) 
    //this.auth.setAuth(this.authObj) 
    this.auth.resetPassword(form.value.email,form.value.codigo,form.value.senha)
    setTimeout(()=>{

      this.carregando = false
      this.router.navigate(['/auth'])
    }, 5000)
    
  }

  onRegistrar(form: NgForm):void{
    this.carregando = true
    //var login = this.auth.resetPassword(form.value.email) 
    //this.auth.setAuth(this.authObj) 
    this.auth.enviarCodigo(form.value.email)
    setTimeout(()=>{

      this.carregando = false
      
    }, 5000)
  }

  onReset(form: NgForm):void{
    this.carregando = true
    
  }

}
