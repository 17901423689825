import { Auth } from './../../auth.model';
import { Subscription } from 'rxjs';
import { AuthService } from './../../auth.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registrar',
  templateUrl: './registrar.component.html',
  styleUrls: ['./registrar.component.css']
})
export class RegistrarComponent implements OnInit {

  constructor(private authservice: AuthService, private router: Router) { }
  carregando
  subscription: Subscription
  authObj: Auth
  ngOnInit(): void {
    this.carregando = false
    this.authObj = new Auth()
    this.subscription = this.authObj.getConfirm().subscribe((valor)=>{
      //console.log(valor)
      if (valor){
        this.router.navigate(['/codigo'])
      }
    })

  }
  onSubmit(form: NgForm):void{
    //console.log(form.value)
    this.carregando = true
    this.authservice.registrarUsuario(form.value.email, 
                                      form.value.senhareq,
                                      form.value.senhareq2,
                                      form.value.usuario,
                                      form.value.name,
                                      form.value.family_name,
                                      this.authObj )
    //this.auth.setAuth(this.authObj)
    //this.auth.efetuaLogin(form.value.email, form.value.senha)
    setTimeout(() => {
     this.carregando = false
    },4000)
  }
}
