import { Auth } from './views/auth/auth.model';

import { AuthInterceptorService } from './views/auth/auth/auth-interceptor.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import { ToolbarComponent } from './components/template/toolbar/toolbar.component';
import { NavComponent } from './components/template/nav/nav.component';
import {MatCardModule} from '@angular/material/card';
import { CriadorComponent } from './views/criador/criador.component';
import { PropositoComponent } from './views/proposito/proposito.component';
import { TrilhaComponent } from './views/trilha/trilha.component'
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatGridListModule} from '@angular/material/grid-list';
import { AulaComponent } from './components/aula/aula.component';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { AuthComponent } from './views/auth/auth/auth.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthnewComponent } from './views/auth/authnew/authnew.component'
import { RegistrarComponent } from './views/auth/registrar/registrar/registrar.component';
import { ConfirmarComponent } from './views/auth/registrar/confirmar/confirmar.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http'
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { LogoComponent } from './components/template/logo/logo.component';
import { CertificacoesComponent } from './views/certificacoes/certificacoes.component';
import { SidenavComponent } from './components/template/sidenav/sidenav.component';
import { SimuladoComponent } from './views/certificacoes/simulado/simulado.component';
import {MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio';
import {MatCheckboxModule, MAT_CHECKBOX_CLICK_ACTION} from '@angular/material/checkbox';
import { MenuComponent } from './views/certificacoes/menu/menu.component';
import {MatSelectModule} from '@angular/material/select';
import { MenutrilhaComponent } from './views/menutrilha/menutrilha.component';
import { ResetComponent } from './views/auth/reset/reset.component';
@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    NavComponent,
    CriadorComponent,
    PropositoComponent,
    TrilhaComponent,
    AulaComponent,
    AuthComponent,
    AuthnewComponent,
    RegistrarComponent,
    ConfirmarComponent,
    LogoComponent,
    CertificacoesComponent,
    SidenavComponent,
    SimuladoComponent,
    MenuComponent,
    MenutrilhaComponent,
    ResetComponent
    

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatGridListModule,
    YouTubePlayerModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSnackBarModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule
   
  ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
    {provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'check'},
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'accent' },
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
