import { Questoes } from './questoes.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { stringify } from 'querystring';
@Injectable({
  providedIn: 'root'
})
export class SimuladoService {

  qtdQuestoes: number
  questoes: Questoes[]
  url = "https://lm9z9j16xh.execute-api.sa-east-1.amazonaws.com/prod/pactitioner-"
  constructor(private http: HttpClient) { }

  buscaQuestoesPractitioner(){
    this.http.get( this.url ,{headers: new HttpHeaders()     
      .set("tabela","certi_practitioner")
      .set("limite",  this.qtdQuestoes.toString())
    })
             .pipe(map((reponseData: {body: Questoes[] })=>{
                const postArray = []
                for (const key in reponseData.body){
                  if (reponseData.body.hasOwnProperty(key)){
                    reponseData.body[key].alternativas.forEach(alternativa=>{
                      alternativa.selecionado = false
                    })
                    postArray.push({...reponseData.body[key]})
                  }
                }
                return postArray
             }))
             .subscribe( 
                response => {
                  //console.log(response)
                  this.questoes = response
                }   
             )
  }

  getItem(id: number){
    return this.questoes[id]
  }

  getQtdItems(): number{
    return this.questoes.length
  }

}
