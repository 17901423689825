import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { MenuService } from '../trilha/menu.service';

@Component({
  selector: 'app-menutrilha',
  templateUrl: './menutrilha.component.html',
  styleUrls: ['./menutrilha.component.css']
})
export class MenutrilhaComponent  implements OnInit, OnDestroy  {
  isAuth = false
  private userSub: Subscription
  constructor(private authService: AuthService,
              private router: Router,
              private menutrilha: MenuService) { }
  showFiller = false;

  ngOnInit(): void {
    this.userSub =  this.authService.user.subscribe(user=>{
      this.isAuth = !user ? false : true
    })
    
  }

  ngOnDestroy():void{
    if(this.userSub){
      this.userSub.unsubscribe()
    }
    
  }

  settrilha(id:string){
    //console.log("setando: "+id)
    this.menutrilha.setIdTrilha(id)
    
  }

  onLogout(){
    this.authService.logout()
    this.router.navigate(['auth'])
  }
}
