import { AuthService } from './views/auth/auth.service';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';

export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})

export class AppComponent implements OnInit, OnDestroy{
  title = 'aulasAWS';
  subscription: Subscription;
  constructor(private authService: AuthService,
              private router: Router){
                this.subscription = router.events.subscribe((event) => {
                  if (event instanceof NavigationStart) {
                    browserRefresh = !router.navigated;
                  }
              });
  }
  ngOnInit(){
    this.authService.autoLogin()
  }
  ngOnDestroy(){
  }
}
