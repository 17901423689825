import { Auth } from './../../auth.model';
import { AuthService } from './../../auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.css']
})
export class ConfirmarComponent implements OnInit {
  authObj: Auth
  carregando
  constructor(private auth: AuthService,
              private router: Router) { }

  ngOnInit(): void {
    this.carregando = false
  }

  onSubmit(form: NgForm):void{
    this.carregando = true
    //console.log(form.value)
    //this.auth.setAuth(this.authObj)
    this.auth.efetuaLogin(form.value.email, form.value.senha, form.value.codigo)
    setTimeout(() => {
      this.authObj = this.auth.getAuth()
      //console.log(this.auth.user)
      if(this.auth.user.value != undefined){
        this.router.navigate(['/menutrilha'])
      }else{
        this.carregando = false
        form.value.email = "";
        form.value.senha = "";
        form.value.codigo = "";
      }
    },5500)
  }


}
