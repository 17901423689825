
<div class="alinhamento" *ngIf="!carregando"> 
<mat-card class="card mat-elevation-z12">
    <mat-card-title>
        Login
    </mat-card-title>
    
<form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)">
    <mat-form-field>
        <input matInput placeholder="Conta ou E-mail" ngModel
            name="email" required >
    </mat-form-field>
    <mat-form-field>
        <input matInput 
               placeholder="Senha" ngModel
               name="senha" required 
               minlength="6" type="password"> 
    </mat-form-field>
 
<button mat-raised-button
color="primary" type="submit"
[disabled]="!authForm.valid">
 Logar
</button>


<button mat-raised-button type="button"
(click)="onRegistrar()">
Registrar-se
</button>



<button mat-raised-button type="button"
(click)="onConfirmar()">
Confirmar Conta
</button>

<button mat-raised-button type="button"
(click)="onReset()">
Recuperar Senha
</button>

</form>
</mat-card>
</div>

<div *ngIf="carregando" >
    <p class="spinnertrilha"><mat-spinner color="accent" ></mat-spinner></p>
    
</div>