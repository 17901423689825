
<div class="alinhamento"> 
    <mat-card class="card mat-elevation-z12">
        <mat-card-title>
            Login
        </mat-card-title>
        
    <form #authFormnew="ngForm" (ngSubmit)="onSubmit(authFormnew)">
        <mat-form-field>
            <input matInput placeholder="E-mail" ngModel
                name="email" required >
        </mat-form-field>
        <mat-form-field>
            <input matInput 
                   placeholder="Senha" ngModel
                   name="senha" required 
                   minlength="6" type="password"> 
        </mat-form-field>
        
            <mat-form-field >
                <input matInput 
                    placeholder="Nova Senha" 
                    ngModel required
                    name="senhareq"  
                    minlength="6" type="password"> 
            </mat-form-field>
            
            <mat-form-field >
                <input matInput required
                    placeholder="Nova Senha Confirmação" 
                    ngModel 
                    name="senhareq2"  
                    minlength="6" type="password"> 
            </mat-form-field>
       
    <button mat-raised-button
    color="primary" type="submit"
    [disabled]="!authFormnew.valid">
     Logar
    </button>
    
    <button mat-raised-button
    >
    Registrar-se
    </button>
    </form>
    </mat-card>
    </div>