import { SimuladoService } from './../simulado/simulado.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
interface Quantidade {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})


export class MenuComponent implements OnInit {
  quantidade: Quantidade[]
  constructor(private router: Router, private simulado: SimuladoService) { }
  
  ngOnInit(): void {
    this.quantidade  = [
      {value: '50 Questões', viewValue: '50'},
      {value: '100 Questões', viewValue: '100'},
      {value: '150 Questões', viewValue: '150'}
      
    ];
  
  }

  acessarSimulado(qtdSimu: any){
    //define quantas questões terá o simulado
    this.simulado.qtdQuestoes = qtdSimu 
    //console.log("Cristroxão " + qtdSimu)
    this.router.navigate(['/certi/1'])
  }

}
