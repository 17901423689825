import { ResetComponent } from './views/auth/reset/reset.component';
import { CertificacoesComponent } from './views/certificacoes/certificacoes.component';
import { ConfirmarComponent } from './views/auth/registrar/confirmar/confirmar.component';
//import { ConfirmarComponent } from './view/auth/registrar/confirmar/confirmar.component';
import { AuthnewComponent } from './views/auth/authnew/authnew.component';
import { AuthComponent } from './views/auth/auth/auth.component';
import { TrilhaComponent } from './views/trilha/trilha.component';
import { PropositoComponent } from './views/proposito/proposito.component';
import { CriadorComponent } from './views/criador/criador.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegistrarComponent } from './views/auth/registrar/registrar/registrar.component';
import { MenutrilhaComponent } from './views/menutrilha/menutrilha.component';


const routes: Routes = [{
  path:"",
  component: CriadorComponent
},
{
  path:"criador",
  component: CriadorComponent
},
{
  path:"reset",
  component: ResetComponent
},
{
  path:"proposito",
  component: PropositoComponent
},
{
  path:"trilhas",
  component: TrilhaComponent
},
{
  path:"menutrilha",
  component: MenutrilhaComponent
},
{
  path:"trilhas/:aulaID",
  component: TrilhaComponent
},
{
  path:"auth",
  component: AuthComponent
},
{
  path:"authnew",
  component: AuthnewComponent
},
{
  path:"registrar",
  component: RegistrarComponent
},
{
  path:"codigo",
  component: ConfirmarComponent
},
{
  path:"certi",
  component: CertificacoesComponent
},
{
  path:"certi/:certi",
  component: CertificacoesComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
