<div class="grid" *ngIf="!carregando">
    <div class="format" >
        <div > 
            <app-aula> </app-aula>
        </div>
        <div *ngIf="aulaselecionada.material">
            <button mat-raised-button (click)="download()" color="primary" class="btn">Download - Material de Apoio</button>
        </div>
        
    </div>

    <div class="formatmenu" > 
        <div  >
            <app-aula class="videocelular" ></app-aula>
        </div>
        <div >
            <mat-accordion class="example-headers-align" *ngFor="let menu of menulateral">
                <mat-expansion-panel (opened)="setStep(0)" hideToggle>
                    <mat-expansion-panel-header >
                        <mat-panel-title >
                        {{menu.moduloDesc}}
                        </mat-panel-title>
                        <mat-icon> {{menu.icone}} </mat-icon>
                    </mat-expansion-panel-header>
                    <div *ngFor="let item of aulas">
                        <mat-action-row *ngIf="menu.modulo==item.modulo" >
                        <div class="aulas">
                            <a routerLink="/trilhas/{{ item.aulaID }}">
                            
                                <mat-icon *ngIf="item.material" class="icone"> attach_file </mat-icon> 
                                {{item.tituloID}} 
                            
                            <br />
                            </a>
                        </div> 
                        </mat-action-row>
                    </div>                  
                </mat-expansion-panel>
    
            
            </mat-accordion>
        </div>
    </div>



</div>
<div *ngIf="carregando" >
    <p class="spinnertrilha"><mat-spinner color="accent" ></mat-spinner></p>
    
</div>