<div class="pagina">
<div class="Fundo">

    <div class="foto" [ngStyle]="{backgroundImage:'url(../../../../assets/image/back.jpg)'}" >
    </div>
</div>
<div class="posicao">
    <mat-card class="card mat-elevation-z12">
        <mat-card-content> 
        <router-outlet></router-outlet>
        </mat-card-content>
    </mat-card>
</div>
</div>