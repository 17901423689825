import { BehaviorSubject, Observable } from 'rxjs';
import { CognitoUserPool } from 'amazon-cognito-identity-js';

  export class Auth {
    private poolData = {
        UserPoolId: 'us-east-1_gNYpjquIR',
        ClientId: '7l7r42j0vs8e4ei1soinchhjkh'
      }

      private atributos:any

      private Pool: CognitoUserPool
      newpassreq: BehaviorSubject<boolean> 
      confirm: BehaviorSubject<boolean> 


      constructor(){
        this.newpassreq = new BehaviorSubject<boolean>(false)
      }
      setValue(newValue): void {
        this.newpassreq.next(newValue);
      }

      getValue(): Observable<boolean> {
        return this.newpassreq.asObservable();
      }
      setConfirm(newValue): void {
        this.newpassreq.next(newValue);
      }

      getConfirm(): Observable<boolean> {
        return this.newpassreq.asObservable();
      }

      getpoolData(): CognitoUserPool{
        return this.Pool = new CognitoUserPool(this.poolData)
      }

      getAT():any{
        return this.atributos
      }
      setAT(at: any){
        this.atributos = at
      }
  }